<script setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";

const storeConfig = useStoreConfig()
const { themeComponents, isStickyNavigation } = storeToRefs(storeConfig)

const mobileSidebarOpen = useState("mobileSidebarOpen", () => false)

const {isShowFilter} = useHelper()
const {resetFilters} = useFilter()

const CategoryFilterComponent = shallowRef()
switch(unref(themeComponents)?.category_filter_component) {
  case 'CategoryFilterAirlife':
    CategoryFilterComponent.value = resolveComponent('LazyTemplatesListingCategoryFilterAirlife')
    break;
  default :
    CategoryFilterComponent.value = resolveComponent('LazyTemplatesListingCategoryFilterDefault')
}

const listingTemplate = computed(() => unref(themeComponents)?.listing_component);

const listingFilter = ref();

const props = defineProps(['hideCategoryList', 'hideMobile'])
// const listingFilterStyle = computed(() => {
//   return {
//     maxHeight : listingFilter.value?.offsetHeight
//   }
// })
</script>

<template>
  <div>
    <!-- Desktop Filter -->
    <div class="text-primary sticky" :class="[isStickyNavigation ? 'top-24' : 'top-6', {'hidden lg:block' : listingTemplate !== 2 || hideMobile}]" v-if="isShowFilter">
      <div class="filter-wrapper scroll-x" ref="listingFilter">
        <component :is="CategoryFilterComponent" />
      </div>
    </div>

    <!-- Mobile Filter -->
    <ClientOnly>
      <TransitionRoot as="template" :show="mobileSidebarOpen">
        <Dialog as="div" class="relative z-300 lg:hidden" @close="mobileSidebarOpen = false">
          <TransitionChild
              as="template"
              enter="transition-opacity ease-linear duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black bg-opacity-40"/>
          </TransitionChild>

          <div class="fixed inset-0 z-300 flex items-end">
            <TransitionChild
                as="template"
                enter="transition ease-in-out duration-300 transform"
                enter-from="translate-y-full"
                enter-to="translate-y-0"
                leave="transition ease-in-out duration-300 transform"
                leave-from="translate-y-0"
                leave-to="translate-y-full"
            >
              <DialogPanel class="relative flex w-full h-5/6 flex-1 flex-col bg-white/90 backdrop-blur-2xl text-primary pt-4 rounded-t-3xl">
                <div class="flex justify-between items-center mb-2.5 px-4">
                  <div>
                    <div class="font-medium">{{$t('listing.filter.filter-and-sorting')}}</div>
                    <!--                  <a class="text-sm underline hover:text-secondary" href="javascript:;" @click.prevent="resetFilters" >{{$t('listing.filter.clean-all')}}</a>-->
                  </div>
                  <LucideX
                      @click="mobileSidebarOpen = false"
                      class="h-6 w-6 text-primary cursor-pointer"
                      aria-hidden="true"
                  />
                </div>

                <div class="w-full h-full overflow-y-auto px-4">

                  <!-- Filters -->
                  <component :is="CategoryFilterComponent" />

                  <div class="py-1.5 mb-2">
                    <FormButton color="primary" class="w-full" @click="mobileSidebarOpen = false">{{$t('listing.filter.list-products')}}</FormButton>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>
    </ClientOnly>
  </div>
</template>