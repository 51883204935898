<script setup>

const storeConfig = useStoreConfig()
const { themeComponents } = storeToRefs(storeConfig)

const CategoryHeaderComponent = shallowRef()
switch(unref(themeComponents)?.category_header_component) {
  case 'CategoryHeaderAirlife':
    CategoryHeaderComponent.value = resolveComponent('LazyTemplatesListingCategoryHeaderAirlife')
    break;
  case 'CategoryHeaderKozmet':
    CategoryHeaderComponent.value = resolveComponent('LazyTemplatesListingCategoryHeaderKozmet')
    break;
  default :
    CategoryHeaderComponent.value = resolveComponent('LazyTemplatesListingCategoryHeaderDefault')
}
</script>

<template>
  <component :is="CategoryHeaderComponent" />
</template>